<template>
  <div class="edit_userinfo">
    <Nav navType="fun_text" :textCon="typeName">
      <template v-slot:rightText>
        <span style="color:#ff8000;font-size:13px" @click="save">保存</span>
      </template>
    </Nav>
    <Field v-model="userData.real_name" minlength="4" maxlength="20" v-if="typeName=='修改昵称'" />
    <Field type="textarea" v-model="userData.sign" maxlength="20" v-else-if="typeName=='修改签名'"/>
    <radio-group v-model="userData.sex" v-else>
      <cell-group>
        <cell title="男" clickable @click="radio = '1'">
          <radio slot="right-icon" name="1" />
        </cell>
        <cell title="女" clickable @click="radio = '2'">
          <radio slot="right-icon" name="2" />
        </cell>
      </cell-group>
    </radio-group>
    <div class="tips" v-if="typeName=='修改昵称'">4-20个字符，可由中英文、数字、“-”、“_”组成</div>
    <div class="tips" v-else-if="typeName=='修改签名'">请控制在20字内</div>
  </div>
</template>
<script>
import Nav from "@components/public/searchTop";
import Setting from "@/api/setting"
import { Field, RadioGroup, Radio, Cell, CellGroup } from "vant"
export default {
  data() {
    return {
      typeName:"",
      userData:{},
      radio: ""
    };
  },
  mounted(){
    this.init()
  },
  methods:{
    init(){
      this.userData = Object.assign({},this.$route.query.data);
      if ( this.$route.query.type == 1 ) { this.typeName = "修改昵称"}
      else if ( this.$route.query.type == 2 ) { this.typeName = "修改签名"}
      else {this.typeName = "修改性别"}
    },
    async save(){
      var data = Object.assign({},this.userData)
      try {
        const res = await Setting.EditUserInfo(data);
        if (res.code == 200) {
          this.$router.go(-1)
        } else{
          console.log(res);
        }
      } catch(err) {
        console.log(err);
      }
    },
  },
  components: {
    Nav,
    Field,
    RadioGroup,
    Radio,
    Cell,
    CellGroup
  }
};
</script>
<style lang="scss" scoped>
.edit_userinfo {
  width: 100%;
  height: 100%;
  padding-top: 53px;
  font-family:PingFang SC;
  padding-bottom: 1px;
  background-color: #f3f2f2;
  position: relative;
  .tips{
    margin-top: 10px;
    font-size: 12px;
    color: #999;
    padding-left: 12px;
  }
}
</style>

<style lang="scss">
.edit_userinfo {
  .my-search {
    height: 0;
  }
}
</style>
